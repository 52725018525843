import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getEnv } from '@logic-suite/shared/utils';

if (environment.production) {
  enableProdMode();
}

// Only initialize Sentry if we have a semantic version (from a git tag)
const version = environment.version;
const isSemver = /^\d+\.\d+\.\d+/.test(version || '');

if (isSemver) {
  Sentry.init({
    dsn: getEnv('sentryDSN'),
    release: version,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /noova.no\/api/],
    environment: getEnv('env'),
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
